* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Helvetica Neue', sans-serif;
  font-family: 'Poppins', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  min-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-accent-color: #13A3DC;
  /* --main-accent-color: orange; */
  --main-gray: #808184;
  --medium-gray: #8A8A8A;
  --main-typo-gray: #152831;
  --dark-gray: #8a8a8a;
  --bg-gray: #F4F4F4;

  --header-height: 80px;
}
