.profile-container {
    display: inline-flex;
    padding: 8px 20px;
    font-size: 1rem;
    align-items: center;
    min-height: 72px;
    z-index: 33;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.initials-circle {
    background-color: #EB5757;
    /* padding: 5px;  */
    border-radius: 50%;
    height: 32px;
    width: 32px;
    min-height:32px;
    min-width: 32px;
    max-width: 32px;
    max-height: 32px;
    display: inline;
    position: relative;
    bottom: 6px;
    font-size: 0.8rem;
    margin: 0 10px;
    color: #fff;
    line-height: 0.8rem;
    /* box-sizing:content-box; */
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.initials {
    /* background-color: #EB5757; */
    /* padding: 5px;  */
    /* border-radius: 100%; */
    /* height: 32px; */
    /* width: 32px; */
    /*min-height: 32px;
    min-width: 32px;
    max-width: 32px;
    max-height: 32px; */
    display: inline;
    /* position: relative; */
    /* bottom: 6px; */
    /* font-size: 0.8rem; */
    /* margin: 0 20px; */
    /* color: #fff; */
    /* overflow: hidden; */
}

.arrow {
    border-top: 3px solid black;
    border-right: 3px solid black;
    height: 12px;
    width: 12px;
    min-width: 12px;
    min-height: 12px;
    display: inline-block;
}

.actions-menu {
    position: absolute;
    right: 0;
    top: -30px;
    border-top: 1px solid #fff;
    /* border-right: 1px solid #fff; */
    /* border-left: 1px solid #000; */
    /* border-bottom: 1px solid #000; */
    z-index: 5;
    background-color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 2px 0px 5px 0px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
}
.actions-menu-item {
  display: inline-flex;
  padding: 8px 0;
}

.actions-menu-animation-closing {
    animation: hideMenu .5s forwards;
  }
  
  /* The animation code */
  @keyframes hideMenu {
    0% {top: 72px; opacity: 1}
    100% {top: -30px; opacity: 1}
  }
  
  .actions-menu-animation-opening {
    animation: openMenu .5s forwards;
  }
  
  /* The animation code */
  @keyframes openMenu {
    0% {top: -30px; opacity: 1}
    100% {top: 72px; opacity: 1}
  }

  .logout-icon-container {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
  }

  /* .logout-icon:hover,
  .active > .logout-icon  {
    background-image: url(../../assets/icons/logoutActive-icon.svg);
  } */
