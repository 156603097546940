.project-card {
    min-height: 80px;
    width: 45%;
    border-radius: 5px;
    margin: 20px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 20%);
}

.project-card-part {
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-name {
    width: 90%;
    background-color: #fff;
    border: 1px solid #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.project-arrow {
    width: 10%;
    background-color: rgb(19, 163, 220);
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    border: 1px solid rgb(19, 163, 220);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}