.App {
  text-align: center;
  background-color: #f5f5f5;
  height: 100%;
  /* height: 100vh; */
  height: 100%;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 10vh;
  height: var(--header-height);
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
  background-color: #fff;
}

.App-nav {
  background-color: #fff;
  /* min-height: 100vh; */
  /* min-height: 150px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  /* width: 200px; */
  width: 100%;
  /* border-right: 1px solid cadetblue; */
  top: 0;
  
  
}

.App-nav-animation-closing {
  animation: hideNav 1s forwards;
}

/* The animation code */
@keyframes hideNav {
  0% {width: 200px;}
  100% {width: 70px;}
}

.App-nav-animation-opening {
  animation: openNav 1s forwards;
}

/* The animation code */
@keyframes openNav {
  0% {width: 70px;}
  100% {width: 200px;}
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-main {
  /* padding-left: 120px; */
  background-color: #f5f5f5;
  /* padding-right: 2%; */
  height: 100%;
  padding-top: 120px;
  margin: 0 2%;
  padding-bottom: 50px;
}

.login {
  padding-left: 0;
}


.main-content-header {
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
}
.main-content-header p {
  width: 50%;
}

select,
select:focus,
select:focus-visible,
select:active {
  border: 1px solid #fff;
  outline: none;
  font-family: 'Poppins', sans-serif;
}

.p10 {
  padding: 10px 0;
}
