.output-table {
    /* background-color: #EBEBEB; */
    padding: 5px;
    border-spacing: 15px;

}

.output-table:nth-child(even) {
    /* background-color: #d6d6d6 */
}

.output-table td {
    border-radius: 5px;
}
