.main-nav {
    list-style: none;
    /* margin: 0 auto; */
    padding: 0;
    display: block;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 0;

}
.main-nav li {
    /* display: inline; */
    display: block;
    margin: 10px 2px;
    font-size: 1rem;
}

.main-nav a {
    color: var(--main-accent-color);
}

.main-nav .link {
  color: var(--main-accent-color);
  cursor: pointer;
  text-decoration: underline;
}

.App-nav {
    background-color: #fff;
    /* min-height: 100vh; */
    min-height: 72px;
    display: flex;
    flex-direction: row;
    /* align-items: start; */
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: var(--main-typo-gray);
    position: fixed;
    /* width: 90px; */
    width: 100%;
    /* border-right: 1px solid white; */
    top: 0;
    z-index: 60;
    padding: 0 10px;
    font-size: 14px;
    -webkit-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.2);
    padding: 0 20px;
  }
  
  .App-nav-animation-closing {
    animation: hideNav 1s forwards;
  }
  
  /* The animation code */
  @keyframes hideNav {
    0% {width: 200px;}
    100% {width: 90px; font-size: 14px;}
  }
  
  .App-nav-animation-opening {
    animation: openNav 1s forwards;
  }
  
  /* The animation code */
  @keyframes openNav {
    0% {width: 90px; font-size: 14px;}
    100% {width: 200px;}
  }

  .logo {
    width: 50px;
    width: auto;
    cursor: pointer;
  }

  .calc-icon {
    background-image: url(../../assets/icons/calculator-icon.svg);
    width: 27px;
    height: 32px;
    display: inline-block;
    margin-right: 10px;
  }

  .calc-icon:hover,
  .active > .calc-icon  {
    background-image: url(../../assets/icons/calculatorActive-icon.svg);
  }

  .logout-icon {
    background-image: url(../../assets/icons/logout-icon.svg);
    width: 36px;
    height: 36px;
    display: none;
    /* margin-right: 10px; */
  }

  .logout-icon:hover,
  .active > .logout-icon  {
    background-image: url(../../assets/icons/logoutActive-icon.svg);
  }

