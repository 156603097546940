.logo {
  width: 50px;
  width: auto;
}

.auth {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: left;
  /* color: #13a3dc; */
  color: #808184;
}

.auth h1 {
  text-align: left;
  /* color: #13a3dc; */
  color: var(--main-accent-color);
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: #808184 ;
  font-weight: normal;
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: #F8F8F8;
  color: var(--main-accent-color);
  border-radius: 4px;
  border: 1px solid #F8F8F8;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: var(--main-accent-color);
  border: 1px solid var(--main-accent-color);
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  font-weight: 600;
  width: 230px;
  margin-bottom: 10px;
}

.actions button:hover {
  background-color: #fff;
  color: var(--main-accent-color);
}

.buttonInfo {
  margin-bottom: 30px;
}
.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: #fff;
  color: #13A3DC;
  ;
}

.auth hr.hr {
  color: #d9d9d9;
  background-color: #d9d9d9;
  border-width: 0;
  height: 1px;
}

.resetPassword {
  text-decoration: none;
  color: var(--main-accent-color);
  cursor: pointer;
  text-align: right;
  font-size: 0.8rem;
}

.link {
  cursor: pointer;
  color: var(--main-accent-color);
  text-decoration: underline;
}
.googleButton {
  font-size: 1rem;
    margin: 30px 0;
   background-color: #E5F1FB;
   color: var(--main-accent-color);
   padding: 5px 20px;
   border: 1px solid #E5F1FB;
   cursor: pointer;
   display: flex;
   align-items: center;

  
}
.googleButton img {
  margin-right: 15px;
}