.admin-notes {
    margin-top: 10px;
    border: 1px solid var(--main-accent-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    /* position: absolute; */
    /* right: 5%; */
    padding: 10px;
    /* bottom: 5px; */
    max-height: 180px;
    max-width: 700px;
    /* background-color: rgb(255, 255, 255); */
    overflow-y: scroll;
    text-align: left;
    font-size: 14px;
}

.table-wrapper input.search-input {
    padding: 10px;
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif;
}

.table-wrapper select {
    padding: 8px;
}

.custom-table tbody tr:first-child {
    border-spacing: 10px;
}

.list-btn {
    margin: 0 3px;
    display: inline-block;
    white-space: nowrap;
    border: 2px solid var(--main-accent-color);
    border-radius: 3px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.square-button {
    width: 42px;
    height: 42px;
    border: 2px solid var(--main-accent-color);
    background-color: #fff;   
    opacity: 1;
}

.square-button.light path {
    fill: var(--main-accent-color);
}

.square-button.primary path {
    fill: #fff;
}
.square-button:hover {
   opacity: 0.5; 
}

.square-button:disabled {
    border: 2px solid #BDBDBD;
    color: #BDBDBD;
    opacity: 0.5; 
    cursor: not-allowed;     
 }
 .square-button:disabled path{
   fill: #BDBDBD; 
 }
 .square-button.accent-color-button:disabled path{
    fill: #fff; 
        border: 2px solid #BDBDBD;

  }

.icon-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.adj-display {
    display: flex;
    align-items: center;
}

.rectangle-button {
    width: 120px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.rectangle-button div {
    font-size: 16px;
}

.accent-color-button {
    background-color: var(--main-accent-color);
    opacity: 1;
}

.accent-color-button:hover {
    opacity: 0.5;
}
.accent-color-button:disabled {
    opacity: 0.5;
    background-color: #BDBDBD;
}

