th {
    font-weight: normal;
}

.first-table,
.first-table th,
.first-table td {
    border-collapse: separate;
    /* border: 1px solid orange; */
    margin: 0 auto;
    padding: 3px;
    min-width: 90px;
}

.first-table th {
    /* background-color: var(--main-accent-color);
    color: white;
    border-radius: 5px; */
    background-color: #fff;
    color: var(--main-typo-gray);
    border-bottom: 1px solid var(--medium-gray);
    font-size: 14px;
    font-weight: 600;
}

.first-table td {
    border-radius: 5px;
    /* background-color: lightgreen; */
    /* font-weight: bold; */
    font-size: 13px;
    color: var(--main-typo-gray);
}

.first-table {
    height: 300px;
    overflow-y: scroll;
    position: relative;
    border-spacing: 10px;
}

.first-table tr:nth-child(odd) {
    /* background-color: #5CA2D91A; */

}
.first-table tr:nth-child(even) {
    /* background-color: #5CA2D933; */
}
.first-table tr.edit-cell:nth-child(odd)>td.non-edit-cell,
.first-table tr.output-table:nth-child(odd) {
    /* background-color: #8081840D; */
}

.first-table tr.edit-cell:nth-child(even)>td.non-edit-cell,
.first-table tr.output-table:nth-child(even) {
    /* background-color: #80818426; */
}

.first-table thead {
    position: sticky;
    top: 0px;
    z-index: 10;
    background-color: white;
    /* box-shadow: inset 0 1px 0 orange,  0 0 1px 0 orange; */
}

.first-table tbody th {
    position: sticky;
    left: -1px;
    z-index: 9;
    background-color: var(--bg-gray);
    color: var(--main-typo-gray);
    border-radius: 5px;
    border-bottom: none;
    font-weight: 400;
}

.first-table input {
    background-color: transparent;
    border: none;
    text-align: center;
    max-width: 100%;
    font-family: 'Poppins', sans-serif;

}

.first-table input:focus-visible {
    background-color: transparent;
    border: none;
    outline: none;
}

.first-table input:-internal-autofill-selected {
    background-color: transparent !important;
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.goal-input {
    display: flex;
}

.forecast-table,
.forecast-table th,
.forecast-table td {
    border-collapse: separate;
    /* border: 1px solid orange; */
    margin: 0 auto;
    padding: 5px;
    min-width: 90px;
}


.tabs-nav {
    /* margin-bottom: 50px; */
    margin-top: 30px;
    text-align: left;
    position: relative;
    bottom: 10px;
    margin-top: 200px;
}
.tabs-nav div {
    display: inline;
    padding: 10px;
    border: 1px solid var(--main-accent-color);
    background-color: var(--main-accent-color);
    color: #fff;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 2px;
    font-weight: bold;
}

.tabs-nav div:first-child {
    margin-left: 0;
    border-left: 1px soild #fff;
}
.tabs-nav div.active {
    background-color: #fff;
    /* position: relative;
    bottom: 5px; */
    /* padding-bottom: 15px; */
    border: 1px solid #eee;
    border-bottom: 1px solid #fff;
    color: #808184;
} 

.scenario-info {
    position: absolute;
    top: 390px;
    right: 20px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-content: flex-start;
    align-items: center;
}

.scenario-info div {
   margin: 0 6px;
}

.forecast-table {
    height: auto;
    /* width: 100%; */
}

/* INPUTS TYPE NUMBER  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

svg {
}
svg path {
}

svg:hover {
}


/* scrollbar */
.input-table::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}
 
.input-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.input-table::-webkit-scrollbar-thumb {
  background-color: var(--main-accent-color);
  outline: 1px solid slategrey;
}