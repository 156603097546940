.header {
    color: var(--main-accent-color);
}

.modalIcon {
    position: relative;
    top: 5px;
    padding-right: 6px;
}

.edittableContainer input,
.edittableContainer input:focus,
.edittableContainer input:focus-visible {
    padding: 10px;
    background-color: #F8F8F8;
    border: 1px solid #F8F8F8;
    border-radius: 5px;
    outline: none;
}